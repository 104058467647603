import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import * as React from "react";
import PropTypes from "prop-types";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers-pro";
import {format, parse} from "date-fns";

const dateFormat = 'dd-MM-yyyy';
const referenceDate = new Date(1970, 0, 1, 0, 0, 0);

//Edit Field
function EditDate(props) {
    const {id, value, field, colDef} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();

    const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

    const handleChange = async (newValue) => {
        const formatValue = format(newValue, "dd-MM-yyyy");
        const isValid = await apiRef.current.setEditCellValue({id, field, value: formatValue, debounceMs: 200});

        if (isValid && rootProps.editMode === GridEditModes.Cell) {
            apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
        }
    };

    return (
        <Component
            value={parse(value, dateFormat, referenceDate)}
            format="dd-MM-yyyy"
            slotProps={{
                textField:{
                    InputLabelProps:{shrink: true},
                    variant: "outlined",
                    fullWidth: true,
                    size: "small"
                }
            }}
            sx={{height:'100%'}}
            onChange={handleChange}
        />
    );
}

EditDate.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

// ===========================
// Render Date
// ===========================
export function renderDate(params) {
    if (params.value == null) {
        return '';
    }
    return <></>;
}

// ===========================
// Render Edit Date
// ===========================
export function renderEditDate(params) {
    return <EditDate{...params} />;
}