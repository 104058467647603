import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './Fonts/dax-medium.woff';
import './Fonts/dax-light.otf';
import * as serviceWorker from './serviceWorker';
import './index.css';
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import {Provider} from "react-redux";
import dataReducer from "./store/reducers/dataTable";
import authReducer from "./store/reducers/auth";
import gpsReducer from "./store/reducers/gps";
import registerReducer from "./store/reducers/register";
import templateAuditReducer from "./store/reducers/templateAudit"
import resetPasswordReducer from "./store/reducers/resetPassword"
import {applyMiddleware, combineReducers,  createStore} from "redux";
import { thunk } from 'redux-thunk'
import {composeWithDevTools} from "@redux-devtools/extension";
import * as Sentry from "@sentry/react";
import createSagaMiddleware from 'redux-saga';
import rootSaga from '././store/sages/sagas';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
    name: `Redux`,
    realtime: true,
    trace: process.env.REACT_APP_ENV === 'Development' ? true : false,
    traceLimit: 25
});

const rootReducer = combineReducers({
    data: dataReducer,
    gps: gpsReducer,
    auth: authReducer,
    reg: registerReducer,
    templateAudit: templateAuditReducer,
    resetPassword: resetPasswordReducer
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

// Run the root saga
sagaMiddleware.run(rootSaga);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({levels: ['error']})
    ],
    release: "rudy@" + process.env.REACT_APP_VERSION,
    enabled: process.env.REACT_APP_ENV === 'Production',
    // Performance Monitoring
    tracesSampleRate: process.env.REACT_APP_ENV === 'Production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: process.env.REACT_APP_ENV === 'Production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    app
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
