import * as actionTypes from './actionTypes';
import axios from "../../api/axios-peer";

/**
 *
 * @param index
 * @returns {{index, type: string}}
 */
export const fetchDataStart = (index) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_START,
        index: index
    }
}

/**
 *
 * @param error
 * @param index
 * @returns {{index, type: string, error}}
 */
export const fetchDataFail = (error, index) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_FAIL,
        error: error,
        index: index
    }
}

/**
 *
 * @param index
 * @returns {{index, type: string}}
 */
export const setTableInitialized = (index) => {
    return {
        type: actionTypes.SET_TABLE_INITIALIZED,
        index: index
    }
}

/**
 *
 * @param url
 * @param index
 * @param numberOfRow
 * @param page
 * @param sortOrder
 * @param urlAddition
 * @param searchParams
 * @param activeTabFilter
 * @param resource
 * @param callback
 * @param routeFilter
 * @returns {{routeFilter: null, resource: null, sortOrder: null, numberOfRow, index, activeTabFilter: null, callback: null, page, type: string, urlAddition: null, url, searchParams: null}}
 */
export const fetchDataRequest = (url, index, numberOfRow, page, sortOrder = null, urlAddition = null, searchParams = null, activeTabFilter = null, resource = null, callback = null, routeFilter = null) => ({
    type: actionTypes.FETCH_DATA_REQUEST_SAGA,
    url,
    index,
    numberOfRow,
    page,
    sortOrder,
    urlAddition,
    searchParams,
    activeTabFilter,
    resource,
    callback,
    routeFilter
});

/**
 * @deprecated use fetchTabsRequest
 * @param url
 * @param index
 * @param resource
 * @param callback
 * @returns {(function(*): void)|*}
 */
export const fetchTabs = (url, index, resource = null, callback = null) => {
    return dispatch => {
        dispatch(fetchDataStart(index));

        url += url.endsWith('/') ? "tabs" : "/tabs"

        const rest = resource ?? axios
        rest.get(url, {
            responseType: 'json',
        }).then(response => {
            if (callback) {
                callback(response.data)
            }
            dispatch(fetchDataSuccess(index, response.data));
        }).catch(error => {
            dispatch(fetchDataFail(error.message, index))
        })
    };
}

/**
 *
 * @param url
 * @param index
 * @param numberOfRow
 * @param page
 * @param sortOrder
 * @param urlAddition
 * @param searchParams
 * @param activeTabFilter
 * @param resource
 * @param callback
 * @param routeFilter
 * @returns {(function(*): void)|*}
 */
export const fetchData = (url, index, numberOfRow, page, sortOrder = null, urlAddition = null, searchParams = null, activeTabFilter = null, resource = null, callback = null, routeFilter) => {

    return dispatch => {
        dispatch(fetchDataStart(index));

        const rest = resource ?? axios

        let questionmarked = url.includes("?");

        if (numberOfRow !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += "per-page=" + numberOfRow;
        }

        if (page !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += "page=" + (page + 1);
        }

        if (urlAddition != null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += urlAddition;
        }

        if (searchParams != null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += searchParams;
        }

        if (routeFilter != null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += routeFilter;
        }


        if (activeTabFilter) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }

            url += activeTabFilter;
        }

        if (sortOrder != null) {
            if (questionmarked === false) {
                url += "?";
            } else {
                url += "&";
            }
            url += "sort=" + sortOrder;
        }

        rest.get(url, {
            responseType: 'json',
        }).then(response => {
            if (callback) {
                callback(response.data)
            }
            dispatch(fetchDataSuccess(index, response.data));
        }).catch(error => {
            dispatch(fetchDataFail(error.message, index))
            dispatch(setFormAlert(true, 'error', error.message))
        })
    };
}

/**
 *
 * @param url
 * @param index
 * @param resource
 * @param callback
 * @returns {{resource: null, index, callback: null, type: string, url}}
 */
export const fetchTabsRequest = (url, index, resource = null, callback = null) => ({
    type: actionTypes.FETCH_TABS_REQUEST_SAGA,
    url,
    index,
    resource,
    callback
});

/**
 *
 * @returns {{type: string}}
 */
export const saveDataStart = () => {
    return {
        type: actionTypes.SAVE_DATA_START
    }
}

/**
 *
 * @param data
 * @param index
 * @param dataId
 * @returns {{data, dataId, index, filters, type: string}}
 */
export const saveDataSucces = (data, index, dataId) => {
    return {
        type: actionTypes.SAVE_DATA_SUCCESS,
        data: data,
        index: index,
        dataId: dataId,
        filters: data.filters
    }
}

/**
 *
 * @param state
 * @returns {{state, type: string}}
 */
export const toggleLoading = (state) => {
    return {
        type: actionTypes.TOGGLE_LOADING,
        state: state,
    }
}

/**
 *
 * @param data
 * @param index
 * @returns {{data, index, filters, type: string}}
 */
export const addDataSucces = (data, index) => {
    return {
        type: actionTypes.ADD_DATA_SUCCESS,
        data: data,
        index: index,
        filters: data.filters,
    }
}

/**
 *
 * @param dataId
 * @param index
 * @returns {{dataId, index, type: string}}
 */
export const deleteDataSuccess = (dataId, index) => {
    return {
        type: actionTypes.DELETE_DATA_SUCCESS,
        dataId: dataId,
        index: index
    }
}

/**
 *
 * @param error
 * @returns {{type: string, error}}
 */
export const saveDataFail = (error) => {
    return {
        type: actionTypes.SAVE_DATA_FAIL,
        error: error
    }
}

/**
 *
 * @param url
 * @param data
 * @param index
 * @param dataId
 * @param callback
 * @param catchError
 * @param baseUrl
 * @returns {(function(*): void)|*}
 */
export const updateData = (url, data, index, dataId, callback = null, catchError = null, baseUrl) => {
    return dispatch => {
        dispatch(saveDataStart());
        axios.put(url, data, baseUrl)
            .then(response => {
                let data = response.data;

                dispatch(saveDataSucces(data, index, dataId))

                if (callback) {
                    callback(data)
                }
                // dispatch(closeDataRecord())
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors)
                    } else {
                        catchError(error.response.data);
                    }
                }

                dispatch(setFormAlert(true, 'error', error.response?.statusText || error.response))
            })
    };
}

/**
 *
 * @param url
 * @param data
 * @param index
 * @param dataId
 * @param callback
 * @param catchError
 * @param baseUrl
 * @returns {(function(*): void)|*}
 */
export const patchData = (url, data, index, dataId, callback = null, catchError = null, baseUrl) => {
    return dispatch => {
        dispatch(saveDataStart());
        axios.patch(url, data, baseUrl)
            .then(response => {
                let data = response.data;

                dispatch(saveDataSucces(data, index, dataId))

                if (callback) {
                    callback(data)
                }
                // dispatch(closeDataRecord())
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors)
                    } else {
                        catchError(error.response.data);
                    }
                }

                dispatch(setFormAlert(true, 'error', error.response?.statusText || error.response))
            })
    };
}

/**
 *
 * @param url
 * @param data
 * @param index
 * @param keepOpen
 * @param callback
 * @param catchError
 * @param skipSaveData
 * @returns {(function(*): void)|*}
 */
export const postData = (url, data, index, keepOpen = false, callback = null, catchError = null, skipSaveData = false) => {
    return dispatch => {

        if (!skipSaveData) {
            dispatch(saveDataStart());
        }
        axios.post(url, data)
            .then(response => {
                let data = response.data;
                if (!Array.isArray(data)) {
                    data = [response.data];
                }
                if (!skipSaveData) {
                    dispatch(addDataSucces(data, index))
                }

                if (callback) {
                    callback(data[0])
                    catchError(null)
                }

                if (!keepOpen) {
                    dispatch(setAddFormOpen(false))
                }
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors)
                        dispatch(setFormAlert(true, 'error', catchError))
                    } else {
                        catchError(error.response.data);
                        if (error.response.data.length > 0) {
                            error.response.data.map((error) => {
                                if (error.field !== undefined) {
                                    dispatch(setFormAlert(true, 'error', error.message))
                                }
                            });
                        } else {
                            catchError = error.response.data.message;
                            dispatch(setFormAlert(true, 'error', catchError))
                        }
                    }
                }
            })
    };
}

/**
 *
 * @param url
 * @param index
 * @param dataId
 * @returns {(function(*): void)|*}
 */
export const deleteData = (url, index, dataId) => {
    return dispatch => {
        axios.delete(url)
            .then(() => {
                dispatch(deleteDataSuccess(dataId, index))
            })
            .catch((error) => {
                dispatch(fetchDataFail(error.message, index))
            })
    };
}

/**
 *
 * @param index
 * @param data
 * @returns {{index, type: string, object: {tabsInitialized: boolean, meta: NetworkMeta, columns, tabs: *, links: *, filters, items, open: boolean, defaultTabIndex: *}}}
 */
export const fetchDataSuccess = (index, data) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_SUCCESS,
        index: index,
        object: {
            items: data.items,
            meta: data._meta,
            links: data._links,
            columns: data.columns,
            filters: data.filters,
            tabs: data.tabs,
            open: false,
            tabsInitialized: true,
            defaultTabIndex: data.defaultTabIndex,
        },
    }
}

/**
 *
 * @param state
 * @param index
 * @param disabled
 * @param openRecord
 * @param action
 * @returns {{openRecord, index, action, disabled: boolean, type: string, open}}
 */
export const openDialogData = (state, index, disabled = true, openRecord, action) => {
    return {
        type: actionTypes.OPEN_DIALOG_TABLE,
        open: state,
        index: index,
        disabled: disabled,
        openRecord: openRecord,
        action: action,
    };
}

/**
 *
 * @param state
 * @param index
 * @param disabled
 * @returns {{openRecord: null, index, disabled: boolean, type: string, open: boolean}}
 */
export const closeDialogData = (state, index, disabled = true) => {
    return {
        type: actionTypes.CLOSE_DIALOG_TABLE,
        open: false,
        index: index,
        disabled: disabled,
        openRecord: null
    };
}

/**
 *
 * @param dataIndex
 * @param index
 * @param action
 * @returns {{openRecord, index, action: null, type: string}}
 */
export const openDataRecord = (dataIndex, index, action = null) => {
    return {
        type: actionTypes.SELECT_DATA_TABLE_ROW,
        openRecord: dataIndex,
        index: index,
        action: action
    };
}

/**
 *
 * @param index
 * @returns {{openRecord: null, index, action: null, type: string}}
 */
export const closeDataRecord = (index) => {
    return {
        type: actionTypes.CLOSE_DATA_TABLE_ROW,
        index: index,
        openRecord: null,
        action: null
    };
}

/**
 *
 * @param dataIndex
 * @param index
 * @returns {{selectedRows, index, type: string}}
 */
export const setSelectedTableRows = (dataIndex, index) => {
    return {
        type: actionTypes.SELECT_TABLE_ROWS,
        selectedRows: dataIndex,
        index: index,
    };
}

/**
 *
 * @param handle
 * @returns {{handle, type: string}}
 */
export const setActionHandle = (handle) => {
    return {
        type: actionTypes.SET_ACTION_HANDLE,
        handle: handle,
    };
}

/**
 *
 * @param state
 * @returns {{isDisabled, type: string}}
 */
export const setDisabled = (state) => {
    return {
        type: actionTypes.SET_DISABLED,
        isDisabled: state,
    };
}

/**
 *
 * @returns {{handle: null, type: string}}
 */
export const clearActionHandle = () => {
    return {
        type: actionTypes.SET_ACTION_HANDLE,
        handle: null,
    };
}

/**
 *
 * @returns {{isEdited: boolean, type: string}}
 */
export const setFormNotEdited = () => {
    return {
        type: actionTypes.FORM_EDITED,
        isEdited: false,
    };
}

/**
 *
 * @returns {{isEdited: boolean, type: string}}
 */
export const setFormEdited = () => {
    return {
        type: actionTypes.FORM_EDITED,
        isEdited: true,
    };
}

/**
 *
 * @param error
 * @returns {{type: string, error}}
 */
export const setFormError = (error) => {
    return {
        type: actionTypes.SET_ERROR,
        error: error,
    };
}

/**
 *
 * @param state
 * @param variant
 * @param message
 * @param persist
 * @param duration
 * @returns {{duration: null, variant, state, persist: null, type: string, message}}
 */
export const setFormAlert = (state, variant, message, persist = null, duration = null) => {
    return {
        type: actionTypes.SET_ALERT,
        state: state,
        variant: variant,
        message: message,
        persist: persist,
        duration: duration,
    };
}

/**
 *
 * @param isOpen
 * @returns {{isOpen, type: string}}
 */
export const setAddFormOpen = (isOpen) => {
    return {
        type: actionTypes.SET_ADD_FORM_OPEN,
        isOpen: isOpen
    };
}

/**
 *
 * @param index
 * @param newTab
 * @param currentTab
 * @returns {{tab, index, currentTab: null, type: string}}
 */
export const setCurrentTab = (index, newTab, currentTab = null) => {

    if (newTab === currentTab) {
        return;
    }
    return {
        type: actionTypes.SET_TAB_REQUEST_SAGA,
        index: index,
        tab: newTab,
        currentTab: currentTab
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const resetDataTable = () => {
    return {
        type: actionTypes.RESET_DATA_TABLE
    }
}

/**
 *
 * @param index
 * @param dataId
 * @param data
 * @returns {{dataId, data, index, type: string}}
 */
export const updateDataTableRecord = (index, dataId, data) => {
    return {
        type: actionTypes.UPDATE_DATA_TABLE_RECORD,
        index: index,
        dataId: dataId,
        data: data,
    }
}

/**
 *
 * @param index
 * @param dataId
 * @param data
 * @returns {{dataId, data, index, type: string}}
 */
export const insertDataTableRecord = (index, dataId, data) => {
    return {
        type: actionTypes.INSERT_DATA_TABLE_RECORD,
        index: index,
        dataId: dataId,
        data: data,
    }
}

/**
 *
 * @param index
 * @param rowIndex
 * @returns {{index, rowIndex, type: string}}
 */
export const removeDataTableRecord = (index, rowIndex) => {
    return {
        type: actionTypes.REMOVE_DATA_TABLE_RECORD,
        index: index,
        rowIndex: rowIndex,
    }
}

/**
 *
 * @param index
 * @returns {{index, type: string}}
 */
export const clearDataTableIndex = (index) => {
    return {
        type: actionTypes.CLEAR_DATA_TABLE_INDEX,
        index: index,
    }
}

/**
 *
 * @param index
 * @param action
 * @returns {{index, action, type: string}}
 */
export const setDataTableAction = (index, action) => {
    return {
        type: actionTypes.SET_DATA_TABLE_ACTION,
        index: index,
        action: action
    }
}

/**
 *
 * @param index
 * @param toggled
 * @returns {{toggled, index, type: string}}
 */
export const setExpandedTableRows = (index, toggled) => {
    return {
        type: actionTypes.EXPAND_TABLE_ROWS,
        index: index,
        toggled: toggled
    }
}

/**
 *
 * @param index
 * @param rowIndex
 * @param columnName
 * @param newValue
 * @returns {{newValue, index, rowIndex, type: string, columnName}}
 */
export const updateDataTableSingleValue = (index, rowIndex, columnName, newValue) => {
    return {
        type: actionTypes.UPDATE_DATA_TABLE_VALUE,
        index: index,
        rowIndex: rowIndex,
        columnName: columnName,
        newValue: newValue,
    }
}

/**
 *
 * @param index
 * @param state
 * @param rowIndex
 * @returns {{index, rowIndex, state, type: string}}
 */
export const setDataTableMode = (index, state, rowIndex) => {
    return {
        type: actionTypes.SET_DATA_TABLE_MODE,
        index: index,
        state: state,
        rowIndex: rowIndex,
    }
}

/**
 *
 * @param index
 * @param exportTable
 * @param companyUUID
 * @returns {{index, exportTable, company, type: string}}
 */
export const exportDataTable = (index, exportTable, companyUUID) => {
    return {
        type: actionTypes.EXPORT_DATA_TABLE,
        index: index,
        exportTable: exportTable,
        company: companyUUID
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const clearTableSettings = () => {
    return {
        type: actionTypes.CLEAR_DATA_TABLE_SETTINGS,
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const clearAllTableSettings = () => {
    return {
        type: actionTypes.CLEAR_ALL_DATA_TABLE_SETTINGS,
    };
}

/**
 *
 * @param index
 * @param field
 * @param value
 * @returns {{field, index, type: string, value}}
 */
export const updateReduxFilters = (index, field, value) => {
    return {
        type: actionTypes.UPDATE_REDUX_FILTERS,
        index: index,
        field: field,
        value: value
    };
}