interface Language {
    [key: string]: string;
}

const nl: Language = {
    COLUMNS: "kolommen",
    COLUMN: "kolom",
    ROWS: "rijen",
    ROW: "rij",
    SELECTED: "geselecteerd",
    PAGE: "pagina",
    OF: 'van',
    LICENSE_PLATE: 'Kenteken',
    DATE: 'Datum',
    TIME: 'Tijd',
    VALUE: 'Waarde',
    NO_OPTIONS: 'Geen opties',
    USERS: 'Gebruikers',
    ROLE: 'Rol',
    DELETE: 'Verwijder',
    CREATE: 'Aanmaken',
    READ: 'Lezen',
    UPDATE: 'Wijzigen',
    MORE: 'Meer',
    RESET: 'Reset',
    NO: 'Nee',
    YES: 'Ja',
    VIEW: 'Bekijk',
    PASSWORD: 'Wachtwoord',
    VERSION: 'versie',
    LOGGING_IN: 'Inloggen...',
    WELCOME: 'Welkom',
    INACTIVE: 'Inactief',
    STATE: 'Status',
    DONE: 'Gereed',
    FURTHER: 'Verder',
    BACK: 'Terug',
    SAVED: 'Opgeslagen',

    EXPORT_TO_EXCEL: 'Exporteer naar Excel',
    EXPORT_TO_PDF: 'Exporteer naar PDF',
    EXPORT_TO: 'Exporteer naar',

    //BUTTONS
    BUTTON_ADD: "Voeg toe",
    BUTTON_GENERATE_PIN: "Chauffeur codes",
    BUTTON_CHANGE_LOGO: "Verander logo",
    BUTTON_CLOSE: 'Sluiten',
    BUTTON_EXPORT: 'Exporteren',
    BUTTON_ALL_COLUMNS: 'Alle kolommen',
    BUTTON_VISIBLE_COLUMNS: 'Alleen zichtbare kolommen',
    BUTTON_ACCEPT: 'Accepteren',
    BUTTON_SEND_NEW_ACTIVATION_MAIL: 'Stuur nieuwe activatiemail',
    BUTTON_CHOOSE_COMPANY: 'Kies bedrijf',
    BUTTON_SAVE_PASSWORD: 'Wachtwoord opslaan',
    BUTTON_FORGOT_PASSWORD: 'Wachtwoord vergeten',
    BUTTON_CREATE_AN_ACCOUNT: 'Maak een account aan',
    BUTTON_CREATE_ACCOUNT: 'Maak account aan',
    BUTTON_NEXT: 'Volgende',
    BUTTON_ACTIVATE_NOW: 'Activeer nu',
    BUTTON_ASK_NEW_PASSWORD: 'Vraag nieuw wachtwoord aan',
    BUTTON_GENERATE_PROJECT_REPORT: 'Genereer project overzicht',
    BUTTON_CHANGE_PASSWORD: 'Wachtwoord wijzigen',
    BUTTON_LOGOUT: 'Uitloggen',
    BUTTON_CHANGE_COMPANY: 'Selecteer bedrijf',
    BUTTON_SELECT_REGION: "Selecteer Regio's",
    BUTTON_CANCEL: "Annuleren",
    BUTTON_CHANGE: "Wijzig",
    BUTTON_SEARCH_VIHB: "Zoek VIHB in lijst",
    BUTTON_SAVE: "Opslaan",
    BUTTON_SAVE_TEMPLATE: "Template opslaan",
    BUTTON_CREATE_TEMPLATE: "Maak template",
    BUTTON_DISAPPROVE: "Afkeuren",
    BUTTON_APPROVE: "Goedkeuren",

    // TOOLTIPS
    TOOLTIP_ADD: "Voeg toe",
    TOOLTIP_NEW: 'Nieuw',
    TOOLTIP_CLOSE: 'Sluiten',
    TOOLTIP_OPEN: 'Open',
    TOOLTIP_DELETE: 'Verwijder',
    TOOLTIP_BACK: 'Terug',
    TOOLTIP_DUPLICATE_LINE: 'Dupliceer rij',
    TOOLTIP_DELETE_LINE: 'Verwijder nieuwe regel',
    TOOLTIP_DELETE_SAVED_TABLE_SETTINGS: 'Verwijder opgeslagen tabel instellingen',
    TOOLTIP_DOWNLOAD_WAYBILL: 'Begeleidingsbrieven downloaden',
    TOOLTIP_HIDE_COLUMNS: 'Kolommen verbergen',
    TOOLTIP_GENERATE_PIN: 'Genereer chauffeur codes',
    TOOLTIP_GENERATE_PIN_INFO: 'Uw bedrijf moet een VIHB nummer hebben om chauffeur codes aan te maken',
    TOOLTIP_EXPORT_DATA: 'Exporteren van data naar Excel of PDF',
    TOOLTIP_DELETING_TEMPLATES: 'Templates die verwijderd worden, worden definitief op verwijderd gezet en kunnen niet meer hersteld worden',
    TOOLTIP_EDIT_WAYBILL: 'Deze begeleidingsbrief kan niet worden aangepast.',
    TOOLTIP_EDIT_WAYBILL_MULTIPLE: 'Eén of meer geselecteerde begeleidingsbrieven kunnen niet worden aangepast',
    TOOLTIP_CLICK_TO_SEARCH_COMPANY: 'Klik om te zoeken naar een bedrijf',
    TOOLTIP_CLEAR_FIELD: 'Maak veld leeg',
    TOOLTIP_INFO_ADD_AUTH: 'Vul hier een e-mail adres in met wie het project gedeeld moet worden. Let op gebruiker moet een account binnen FormKlub hebben.',
    TOOLTIP_ADD_MORE_USERS: 'Voeg meer gebruikers toe',
    TOOLTIP_CHANGE_LANGUAGE: 'Verander taal',
    TOOLTIP_NO_COLOR: 'Geen kleur',
    TOOLTIP_CHOOSE_COLOR: 'Kies een kleur',
    TOOLTIP_SEARCH: 'Zoeken',
    TOOLTIP_EDIT: 'Wijzig',
    TOOLTIP_SAVE: 'Opslaan',
    TOOLTIP_INFO_WEIGHT_WAYBILL: 'Dit gewicht wordt gebruikt op de brief',
    TOOLTIP_INFO_WEIGHT_WAYBILL_ONLY_WEIGH_BRIDGE: 'Alleen wegingen accepteren van weegbrug staat aan voor deze brief',
    TOOLTIP_WEIGHING_NOT_USED: 'Weging wordt niet gebruikt voor brief',
    TOOLTIP_DROP_FILES: 'Klik hier of sleep bestanden hierheen om deze toe te voegen',
    TOOLTIP_HIDE_REGIONS: 'Verberg regio',
    TOOLTIP_CRON_TEMPLATE_STATE: 'Onderliggende templates worden automatisch op ingebruik gezet op basis van startdatum en afgerond na passeren einddatum',
    TOOLTIP_SCROLL_DOWN: 'Scroll naar beneden om de template te bekijken',

    // TITLE
    TITLE_DRIVEN_ROUTE: 'Gereden route',
    TITLE_SEARCH_COMPANY: 'Zoek bedrijf',
    TITLE_SHARE_PROJECTS: 'Delen van project',
    TITLE_CANCEL_CHANGE: 'Wijzigingen Annuleren',
    TITLE_CHANGES: 'Wijzigingen',
    TITLE_ARE_YOU_SURE: 'Weet je zeker dat je dit scherm wilt sluiten?',
    TITLE_PROJECT_OVERVIEW: 'Project overzicht',
    TITLE_RELATION_OVERVIEW: 'Relatie overzicht',
    TITLE_TEMPLATE_WAYBILL: 'Template begeleidingsbrieven',
    TITLE_USER_OVERVIEW: 'Gebruiker overzicht',
    TITLE_ORDERTYPE_OVERVIEW: 'Werkzaamhedentype overzicht',
    TITLE_COMPANIES_OVERVIEW: 'Organisatie overzicht',
    TITLE_REGION_OVERVIEW: 'Regio overzicht',
    TITLE_NEW_PASSWORD: 'Vul hier uw nieuwe wachtwoord in voor:',
    TITLE_WHAT_IS_ADDRESS: 'Wat zijn uw gegevens',
    TITLE_WHAT_IS_INVOICE_ADDRESS: 'Wat zijn uw factuurgegevens',
    INVOICE_SAME_AS_ADDRESS: 'Factuuradres hetzeflde als adres',
    TITLE_SEARCH_IN_VIHB_LIST: 'Zoek in bestaande lijst van bedrijven met VIBH nummer',
    TITLE_ADD_RELATION: 'Relatie toevoegen',
    TITLE_SEE_WAYBILL: 'Begeleidingsbrief inzien',
    TITLE_WAYBILLS: 'Begeleidingsbrieven',
    TITLE_RIDES: 'Ritten',
    TITLE_CAUTION: 'Let op',
    TITLE_OPEN_MANUALS: 'Bekijk onze handleidingen',
    TITLE_INSTRUCTION_VIDEO: "Instructie video's",
    TITLE_MANUAL: "Handleiding {{name}}",
    TITLE_ADMIN_SHORTCUTS: "Admin snelkoppelingen",

    // ERRORS
    ERROR_CANNOT_DOWNLOAD_WAYBILL: 'Kan begeleidingsbrieven niet downloaden',
    ERROR_SOMETHING_WENT_WRONG: 'Er gaat iets fout',
    ERROR_LOGO_FILE_TYPE: 'Logo moet één van de volgende bestandstype hebben (.jpg, .jpeg of .png)',
    ERROR_EXPORT_DATA: 'Gegevens kunnen niet worden geëxporteerd',
    ERROR_NO_RESULTS: 'Geen resultaten gevonden',
    ERROR_CANNOT_UPDATE_STATE: 'Kan status niet aanpassen',
    ERROR_CANNOT_PERFORM_ACTION: 'Kan actie niet uitvoeren',
    ERROR_SOMETHING_WENT_WRONG_FETCH: 'Er gaat iets fout in het ophalen van de data',
    ERROR_CANNOT_ADD_ROLES: 'Kan rollen niet toevoegen',
    ERROR_EMAIL_NOT_VALID: 'E-mail is niet geldig',
    ERROR_EMAIL_CANNOT_CHECK: 'Kan niet controleren of email bestaat',
    ERROR_USER_NOT_FOUND: 'Gebruiker niet gevonden',
    ERROR_COMPANY_INACTIVE_TEXT: 'U heeft geen toegang tot het systeem uw bedrijf staat gemarkeerd als inactief',
    ERROR_NO_ACCESS_DRIVER: 'U logt momenteel in met een account dat de rol Chauffeur heeft. Als chauffeur heeft u niet voldoende rechten om in te mogen loggen op dit portaal. Klopt dit niet neem contact op met de beheerder binnen uw organisatie.',
    ERROR_NO_ACCESS_DEFAULT: 'U heeft niet voldoende rechten om gegevens in dit systeem te mogen zien. Klopt dit niet neem contact op met de beheerder binnen uw organisatie.',
    ERROR_USER_NOT_ACTIVATED: 'Dit account is nog niet geverifieerd check uw mail voor de activatie link. Heeft u de mail niet ontvangen of bent u deze kwijt klik op onderstaande knop voor een nieuwe activatiemail',
    ERROR_ACTIVATION_MAIL_NOT_SEND: 'Er is iets fout gegaan activatiemail is niet verstuurd',
    ERROR_PAGE_NOT_FOUND: 'Pagina niet gevonden',
    ERROR_PAGE_NOT_FOUND_TEXT: 'We hebben ons best gedaan, maar het lijkt erop dat de pagina die u zocht niet (meer) bestaat.',
    ERROR_ACCOUNT_INACTIVE: 'Er kan op dit moment niet ingelogd worden omdat dit account staat gemarkeerd als inactief. Om dit account weer actief te maken neem contact op met de beheerder binnen uw organisatie.',
    ERROR_ACCOUNT_NOT_VERIFIED: 'Account is nog niet geactiveerd',
    ERROR_ACCOUNT_NOT_VERIFIED_MAIL: 'Dit account is nog niet geverifieerd check uw mail voor de activatie link.',
    ERROR_NO_COMPANIES_FOUND: 'Geen bedrijven gevonden',
    ERROR_COMPANY_ALREADY_EXISTS: 'Bedrijf bestaat al in FormKlub',
    ERROR_CONTACT_MANAGER_IN_ORGANISATION: 'Neem contact op met de beheerder binnen uw organisatie',
    ERROR_CANNOT_SAVE_CHANGES: 'Kan wijzigingen niet opslaan',
    ERROR_CANNOT_BE_FOUND: 'Kan niet gevonden worden of is verlopen',

    // SUCCESS
    SUCCESS_DATA_EXPORTED: 'succesvol geëxporteerd',
    SUCCESS_DATA_EDITED: 'Succesvol gewijzigd',
    SUCCESS_PASSWORD_CHANGED: 'Wachtwoord succesvol gewijzigd',
    SUCCESS_TEMPLATE_DELETED: 'Template is verwijderd',
    SUCCESS_ROW_DELETED: 'Regel is verwijderd',
    SUCCESS_USER_ADDED: 'Gebruiker is toegevoegd',
    SUCCESS_USER_FOUND: 'Gebruiker gevonden',
    SUCCESS_PROJECT_CREATED: 'Project is aangemaakt',
    SUCCESS_RELATION_CREATED: 'Relatie is aangemaakt',

    // DIALOG TEXTS
    EXPORT_DIALOG_TEXT: 'Welke gegevens wilt u exporteren? Momenteel zijn sommige kolommen in de tabel verborgen. Wilt u de huidige weergave exporteren of alle kolommen?',
    CONFIRM_DELETE_TEMPLATE: 'Bent u absoluut zeker dat u deze template wilt verwijderen?',
    UNSAVED_DATE_WILL_BE_LOST: 'Niet opgeslagen gegevens zullen verloren gaan',
    ACCESS_TO_MULTIPLE_COMPANIES: 'U heeft toegang tot meerdere bedrijven kies een bedrijf om door te gaan.',
    ACCESS_TO_MULTIPLE_COMPANIES_INFO: 'U kunt later op elk moment weer wisselen van bedrijf via het account menu rechts boven in de oranje balk.',
    ACTIVATE_ACCOUNT_INFO_1: 'Activeer je account door op de knop ',
    ACTIVATE_ACCOUNT_INFO_2: 'hieronder te drukken.',
    INFO_MAIL_NEW_PASSWORD: 'Binnen enkele momenten kunt u een email ontvangen met uw nieuwe wachtwoord',
    CREATE_TEMPLATES_INFO_1: 'Wilt u ook templates kunnen aanmaken?',
    CREATE_TEMPLATES_INFO_2: 'Neem in dit geval contact op met ScoreTrace B.V.',
    INFO_MULTI_EDIT_PROJECT: "Momenteel {{count}} project(en) geselecteerd. Door onderstaand veld te wijzigen worden alle geselecteerde projecten geupdate.",
    INFO_MULTI_EDIT_TEMPLATE: "Momenteel {{count}} template(s) geselecteerd. Door onderstaand veld te wijzigen worden alle geselecteerde template's geupdate.",
    INFO_ADMIN_SHORTCUTS: "Onderstaand alle bedrijven met meer dan 1 regio. Overige bedrijven kunnen gekozen worden via 'account -> selecteer bedrijf'",
    TEMPLATE_APPROVED: 'Deze template is goedgekeurd',
    TEMPLATE_DISAPPROVED: 'Deze template is afgekeurd',

    // LABELS
    EMAIL_USER: 'E-mail gebruiker',
    DUTCH: 'Nederlands',
    ENGLISH: 'Engels',
    CHOOSE_COLOR: 'Kies een kleur:',
    CHOOSEN_COLOR: 'Gekozen kleur:',
    ACTIVE: 'Actief',
    NOT_ACTIVE: 'Niet actief',
    FIELD: 'Veld',
    OLD: 'Oud',
    NEW: 'Nieuw',
    AMOUNT: 'Hoeveelheid',
    UNITTYPE: 'Eenheid',
    BY: 'Door',
    TYPE_WEIGHBRIDGE: 'Type weegbrug',
    BONNUMBER: 'Bonnummer',
    WEIGHING_FOR_RIDE: 'Wegingen voor rit',
    CHANGES_FOR_RIDE: 'Wijzigingen voor rit',
    NR: 'Nr.',
    EDITED_BY: 'Gewijzigd door',
    EDITED_ON: 'Gewijzigd op',
    COMPANY_INACTIVE: 'Bedrijf inactief',
    NO_ACCESS: 'Verboden toegang',
    ACTIVATION_SEND: 'Activatiemail verstuurd',
    ACTIVE_ACCOUNT: 'Activeer uw account',
    BACK_TO_START: 'Terug naar het beginscherm',
    BACK_TO_LOGIN: 'Terug naar het loginscherm',
    SELECT_COMPANY: 'Selecteer een bedrijf',
    PASSWORD_CHANGED: 'Wachtwoord aangepast',
    NO_ACCOUNT_FORMKLUB: 'Nog geen account bij FormKlub?',
    EMAIL_ADDRESS: 'E-mailadres',
    EMAIL: 'E-mail',
    ACCOUNT_CREATED: 'Account is aangemaakt',
    ACCOUNT_CREATED_INFO: 'U ontvangt nu van ons op het door u ingevulde e-mailadres een activatiemail. In de ontvangen mail staat een activatielink waarmee u uw account kunt activeren zodra u dit gedaan heeft is het account klaar voor gebruik.',
    ADDRESS: 'Adres',
    STREET: 'Straat',
    INVOICE_ADDRESS: 'Factuuradres',
    CONTACT: 'Contact',
    ACCOUNT: 'Account',
    PHONE: 'Telefoon',
    NAME: 'Naam',
    CREATE_TEMPLATES: 'Templates aanmaken',
    HOUSE_NUMBER: 'Huisnummer',
    HOUSE_NUMBER_ADDITION: 'Toevoeging',
    ZIPCODE: 'Postcode',
    COUNTRY: 'Land',
    CITY: 'Plaats',
    ACCOUNT_DATA: 'Accountgegevens',
    CONTACT_DATA: 'Contactgegevens',
    FIRST_NAME: 'Voornaam',
    INSERTION: 'tussenvoegsel',
    LAST_NAME: 'Achternaam',
    ACCOUNT_CREATE: 'Account Maken',
    COMPANY_NAME: 'Bedrijfsnaam',
    NO_VIHB: 'Heeft uw geen VIHB nummer klik dan',
    HERE: 'hier',
    ROLES: 'Rollen',
    SETTINGS: 'Instellingen',
    DOCUMENTS: 'Documenten',
    SHARE: 'Delen',
    OVERLOADED: 'Overbeladen',
    LINKTMS: 'Koppelen Tms',
    PROJECT_NR: 'Project nr.',
    DESCRIPTION: 'Omschrijving',
    CLIENT: 'Opdrachtgever',
    START_DATE: 'Startdatum',
    END_DATE: 'Einddatum',
    CONTRACT_NR: 'Contract nr.',
    REMARK_PLANNING: 'Opmerking planning',
    REMARK_DRIVER: 'Opmerking chauffeur',
    REMARKS: 'Opmerkingen',
    REGIO: 'Regio',
    MANUALS: 'Handleidingen',
    FAQ: 'Veelgestelde vragen',
    NEED_HELP: 'Hulp nodig?',
    TEMPLATE_COLOR: 'Template kleur',
    DOWNLOADS: 'Downloads',
    TEMPLATE_FROM: 'Template van',
    SEND: 'Versturen',
    CONTACTS: 'Contactpersonen',

    // INDEX
    PROJECT: 'Projecten',
    PROJECTS: 'Projecten',
    REGION: "Regio's",
    TEMPLATE: 'Templates',
    BUSINESSRELATION: 'Relaties',
    ORDERTYPE: 'Order type',
    COMPANY: 'Bedrijf',
    WAYBILL: 'Begeleidingsbrieven',
    RIDES: 'Ritten',
    ORDERTYPES: 'Werkzaamhedentypes',
    COMPANIES: 'Organisaties',
    GOODSITEM: 'Rit',
    GOODSITEMS: 'Ritten',

    // PLACEHOLDERS
    TYPE_TO_SEARCH: 'Type hier om te zoeken...',
    TYPE_AT_LEAST_TO_SEARCH: 'Vul tenminste 3 karakters in om te zoeken',
    CURRENTLY_SEARCHING: 'Aan het zoeken..',
    PLEASE_WAIT: 'Er wordt momenteel veel informatie verzameld. We vragen nog even om uw geduld',
    TYPE_REMARK: 'Type hier uw opmerking...',

    //VALIDATION
    ROLE_MANDATORY: 'Role is required',
    USER_MANDATORY: 'User is required',
    REQUIREMENTS_PASSWORD: 'Wachtwoord moet minimaal 10 karakters bevatten, minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en een speciaal karakter.',
    FIELD_CANNOT_BE_EMPTY: 'Veld mag niet leeg zijn',
    EMAIL_IS_NOT_VALID: 'Vul een geldig e-mail adres in',
    FIELD_IS_MANDATORY: 'Veld is verplicht',

    // STATES
    STATE_TEMPLATE_ALL: 'Alle',
    STATE_ALL: 'Alle',
    STATE_TEMPLATE_RECEIVED: 'Ontvangen',
    STATE_TEMPLATE_CREATED: 'Aangemaakt',
    STATE_TEMPLATE_BUSY: 'In gebruik',
    STATE_TEMPLATE_TEMP_CLOSED: 'Buiten gebruik',
    STATE_TEMPLATE_FINISHED: 'Afgerond',
    STATE_TEMPLATE_DELETED: 'Verwijderd',
    STATE_TEMPLATE_REJECTED: 'Afgewezen',
};

const en: Language = {
    COLUMNS: "columns",
    COLUMN: "column",
    ROWS: "rows",
    ROW: "row",
    SELECTED: "selected",
    PAGE: "page",
    OF: 'of',
    LICENSE_PLATE: 'License plate',
    DATE: 'Date',
    TIME: 'Time',
    VALUE: 'Value',
    NO_OPTIONS: 'No options',
    USERS: 'Users',
    ROLE: 'Role',
    DELETE: 'Delete',
    CREATE: 'Create',
    READ: 'Read',
    UPDATE: 'Edit',
    MORE: 'More',
    RESET: 'Reset',
    NO: 'No',
    YES: 'yes',
    VIEW: 'View',
    PASSWORD: 'Password',
    VERSION: 'version',
    LOGGING_IN: 'Logging in...',
    WELCOME: 'Welcome',
    INACTIVE: 'Inactive',
    STATE: 'State',
    DONE: 'Done',
    FURTHER: 'Further',
    BACK: 'Back',
    SAVED: 'Saved',

    EXPORT_TO_EXCEL: 'Export to Excel',
    EXPORT_TO_PDF: 'Export to PDF',
    EXPORT_TO: 'Exporteer to',

    // BUTTONS
    BUTTON_ADD: "Add new",
    BUTTON_GENERATE_PIN: "Driver pincodes",
    BUTTON_CHANGE_LOGO: "Change logo",
    BUTTON_CLOSE: 'Close',
    BUTTON_EXPORT: 'Export',
    BUTTON_ALL_COLUMNS: 'All columns',
    BUTTON_VISIBLE_COLUMNS: 'only visible columns',
    BUTTON_ACCEPT: 'Accept',
    BUTTON_SEND_NEW_ACTIVATION_MAIL: 'Send new activation mail',
    BUTTON_CHOOSE_COMPANY: 'Choose company',
    BUTTON_SAVE_PASSWORD: 'Save password',
    BUTTON_FORGOT_PASSWORD: 'Forgot password',
    BUTTON_CREATE_AN_ACCOUNT: 'Create an account',
    BUTTON_CREATE_ACCOUNT: 'Create account',
    BUTTON_NEXT: 'Next',
    BUTTON_ACTIVATE_NOW: 'Activate now',
    BUTTON_ASK_NEW_PASSWORD: 'Request new password',
    BUTTON_GENERATE_PROJECT_REPORT: 'Generate project report',
    BUTTON_CHANGE_PASSWORD: 'Change password',
    BUTTON_LOGOUT: 'Log out',
    BUTTON_CHANGE_COMPANY: 'Change company',
    BUTTON_SELECT_REGION: "Select regions",
    BUTTON_CANCEL: "Cancel",
    BUTTON_CHANGE: "Change",
    BUTTON_SEARCH_VIHB: "Search VIHB in list",
    BUTTON_SAVE: "Save",
    BUTTON_SAVE_TEMPLATE: "Save Template",
    BUTTON_CREATE_TEMPLATE: "Create Template",
    BUTTON_DISAPPROVE: "Disapprove",
    BUTTON_APPROVE: "Approve",

    // TOOLTIPS
    TOOLTIP_ADD: "Add new",
    TOOLTIP_NEW: 'New',
    TOOLTIP_CLOSE: 'Close',
    TOOLTIP_OPEN: 'Open',
    TOOLTIP_DELETE: 'Delete',
    TOOLTIP_BACK: 'Back',
    TOOLTIP_DUPLICATE_LINE: 'Duplicate row',
    TOOLTIP_DELETE_LINE: 'Delete new row',
    TOOLTIP_DELETE_SAVED_TABLE_SETTINGS: 'Delete saved table settings',
    TOOLTIP_DOWNLOAD_WAYBILL: 'Download waybills',
    TOOLTIP_HIDE_COLUMNS: 'Hide columns',
    TOOLTIP_GENERATE_PIN: 'Generate driver pincodes',
    TOOLTIP_GENERATE_PIN_INFO: 'Your company must have a VIHB number to create driver codes',
    TOOLTIP_EXPORT_DATA: 'Export data to Excel or PDF',
    TOOLTIP_DELETING_TEMPLATES: 'Templates that are deleted are permanently deleted and cannot be restored',
    TOOLTIP_EDIT_WAYBILL: 'This waybill cannot be edited.',
    TOOLTIP_EDIT_WAYBILL_MULTIPLE: 'One or more selected waybill cannot be edited',
    TOOLTIP_CLICK_TO_SEARCH_COMPANY: 'Click to search company',
    TOOLTIP_CLEAR_FIELD: 'Clear field',
    TOOLTIP_INFO_ADD_AUTH: 'Enter an email address here with whom the project should be shared. Please note that the user must have an account within FormKlub.',
    TOOLTIP_ADD_MORE_USERS: 'Add more users',
    TOOLTIP_CHANGE_LANGUAGE: 'Change language',
    TOOLTIP_NO_COLOR: 'No color',
    TOOLTIP_CHOOSE_COLOR: 'Choose a color',
    TOOLTIP_SEARCH: 'Search',
    TOOLTIP_EDIT: 'Edit',
    TOOLTIP_SAVE: 'Save',
    TOOLTIP_INFO_WEIGHT_WAYBILL: 'This weight is used on the waybill',
    TOOLTIP_INFO_WEIGHT_WAYBILL_ONLY_WEIGH_BRIDGE: 'Only accept weighings from weighbridge is enabled for this waybill',
    TOOLTIP_WEIGHING_NOT_USED: 'Weighing is not used for this waybill',
    TOOLTIP_DROP_FILES: 'Click here or drag and drop the files to this box',
    TOOLTIP_HIDE_REGIONS: 'Hide region',
    TOOLTIP_CRON_TEMPLATE_STATE: 'Underlying templates are automatically put into use based on start date and completed after passing end date',
    TOOLTIP_SCROLL_DOWN: 'Scroll down to view the template',

    // TITLE
    TITLE_DRIVEN_ROUTE: 'Driven route',
    TITLE_SEARCH_COMPANY: 'Search company',
    TITLE_SHARE_PROJECTS: 'Share project',
    TITLE_CANCEL_CHANGE: 'Cancel changes',
    TITLE_CHANGES: 'Changes',
    TITLE_ARE_YOU_SURE: 'Are you sure you want to close this screen?',
    TITLE_PROJECT_OVERVIEW: 'Project overview',
    TITLE_RELATION_OVERVIEW: 'Relation overview',
    TITLE_TEMPLATE_WAYBILL: 'Template waybills',
    TITLE_USER_OVERVIEW: 'User Overview',
    TITLE_ORDERTYPE_OVERVIEW: 'Order type overview',
    TITLE_COMPANIES_OVERVIEW: 'Company overview',
    TITLE_REGION_OVERVIEW: 'Region overview',
    TITLE_NEW_PASSWORD: 'Enter your new password here for:',
    TITLE_WHAT_IS_ADDRESS: 'What is your address',
    TITLE_WHAT_IS_INVOICE_ADDRESS: 'What are your billing details',
    INVOICE_SAME_AS_ADDRESS: 'Billing address same as address',
    TITLE_SEARCH_IN_VIHB_LIST: 'Search in list of companies by VIHB number',
    TITLE_ADD_RELATION: 'Add relation',
    TITLE_SEE_WAYBILL: 'See waybill',
    TITLE_WAYBILLS: 'Waybills',
    TITLE_RIDES: 'Rides',
    TITLE_CAUTION: 'Caution',
    TITLE_OPEN_MANUALS: 'View our manuals',
    TITLE_INSTRUCTION_VIDEO: 'Instruction videos',
    TITLE_MANUAL: "Manual {{name}}",
    TITLE_ADMIN_SHORTCUTS: "Admin shortcuts",

    // ERRORS
    ERROR_CANNOT_DOWNLOAD_WAYBILL: 'Cannot download waybills',
    ERROR_SOMETHING_WENT_WRONG: 'Something went wrong',
    ERROR_LOGO_FILE_TYPE: 'Logo must have one of the following file types (.jpg, .jpeg of .png)',
    ERROR_EXPORT_DATA: 'Data cannot be exported',
    ERROR_NO_RESULTS: 'No results found',
    ERROR_CANNOT_UPDATE_STATE: 'Cannot update state',
    ERROR_CANNOT_PERFORM_ACTION: 'Cannot perform action',
    ERROR_SOMETHING_WENT_WRONG_FETCH: 'Something went wrong while retrieving the data',
    ERROR_CANNOT_ADD_ROLES: 'Cannot add roles',
    ERROR_EMAIL_NOT_VALID: 'Email is not valid',
    ERROR_EMAIL_CANNOT_CHECK: 'Cannot check if email exists',
    ERROR_USER_NOT_FOUND: 'User not found',
    ERROR_COMPANY_INACTIVE_TEXT: 'You do not have access to the system your company is marked as inactive',
    ERROR_NO_ACCESS_DRIVER: 'You are currently logging in with an account that has the Driver role. As a driver you do not have sufficient rights to log in to this portal. If this is not correct, please contact the administrator within your organization.',
    ERROR_NO_ACCESS_DEFAULT: 'You do not have sufficient rights to view data in this system. If this is not correct, please contact the administrator within your organization.',
    ERROR_USER_NOT_ACTIVATED: 'This account has not yet been verified, check your email for the activation link. If you have not received the email or have lost it, click on the button below for a new activation email',
    ERROR_ACTIVATION_MAIL_NOT_SEND: 'Something went wrong activation mail is not send',
    ERROR_PAGE_NOT_FOUND: 'Page not found',
    ERROR_PAGE_NOT_FOUND_TEXT: 'We have done our best, but it seems that the page you were looking for does not exists.',
    ERROR_ACCOUNT_INACTIVE: 'You cannot log in at this time because this account is marked as inactive. To make this account active again, please contact the administrator within your organization.',
    ERROR_ACCOUNT_NOT_VERIFIED: 'Account has not yet been activated',
    ERROR_ACCOUNT_NOT_VERIFIED_MAIL: 'This account has not yet been verified, check your email for the activation link.',
    ERROR_NO_COMPANIES_FOUND: 'No companies found',
    ERROR_COMPANY_ALREADY_EXISTS: 'Company already exists in FormKlub',
    ERROR_CONTACT_MANAGER_IN_ORGANISATION: 'Please contact a manager within your organization',
    ERROR_CANNOT_SAVE_CHANGES: 'Cannot save changes',
    ERROR_CANNOT_BE_FOUND: 'Cannot be found or has expired',

    // SUCCESS
    SUCCESS_DATA_EXPORTED: 'Successfully exported',
    SUCCESS_DATA_EDITED: 'Succesvol edited',
    SUCCESS_PASSWORD_CHANGED: 'Password successfully changed',
    SUCCESS_TEMPLATE_DELETED: 'Template successfully deleted',
    SUCCESS_ROW_DELETED: 'Row is deleted',
    SUCCESS_USER_ADDED: 'User is added',
    SUCCESS_USER_FOUND: 'User found',
    SUCCESS_PROJECT_CREATED: 'Project is created',
    SUCCESS_RELATION_CREATED: 'Relation is created',

    // DIALOG TEXTS
    EXPORT_DIALOG_TEXT: 'What data do you want to export? Currently some columns in the table are hidden. Do you want to export the current view or all columns?',
    CONFIRM_DELETE_TEMPLATE: 'Are you absolutely sure you want to delete this template?',
    UNSAVED_DATE_WILL_BE_LOST: 'Unsaved data will be lost',
    ACCESS_TO_MULTIPLE_COMPANIES: 'You have access to multiple companies choose a company to continue.',
    ACCESS_TO_MULTIPLE_COMPANIES_INFO: 'You can switch companies again at any time via the account menu at the top right of the orange bar.',
    ACTIVATE_ACCOUNT_INFO_1: 'Activate your account by clicking',
    ACTIVATE_ACCOUNT_INFO_2: 'the button below.',
    INFO_MAIL_NEW_PASSWORD: 'Within a few moments you will receive an email with your new password',
    CREATE_TEMPLATES_INFO_1: 'Would you also like to be able to create templates?',
    CREATE_TEMPLATES_INFO_2: 'In this case, please contact ScoreTrace B.V.',
    INFO_MULTI_EDIT_PROJECT: "Currently {{count}} project(s) selected. By changing the field below, all selected projects will be updated.",
    INFO_MULTI_EDIT_TEMPLATE: "Currently {{count}} template(s) selected. By changing the field below, all selected templates will be updated.",
    INFO_ADMIN_SHORTCUTS: "Below all companies with more than 1 region. Other companies can be selected via 'account -> select company'",
    TEMPLATE_APPROVED: 'This template has been approved',
    TEMPLATE_DISAPPROVED: 'This template has been rejected',

    // LABELS
    EMAIL_USER: 'Email user',
    DUTCH: 'Dutch',
    ENGLISH: 'English',
    CHOOSE_COLOR: 'Choose a color:',
    CHOOSEN_COLOR: 'Chosen color:',
    ACTIVE: 'Active',
    NOT_ACTIVE: 'Inactive',
    FIELD: 'Field',
    OLD: 'Old',
    NEW: 'New',
    AMOUNT: 'Amount',
    UNITTYPE: 'Unit type',
    BY: 'By',
    TYPE_WEIGHBRIDGE: 'Type weighbridge',
    BONNUMBER: 'Receipt number',
    WEIGHING_FOR_RIDE: 'Weighing for ride',
    CHANGES_FOR_RIDE: 'Changes for ride',
    NR: 'Nr.',
    EDITED_BY: 'Edited by',
    EDITED_ON: 'Changed on',
    COMPANY_INACTIVE: 'Company is inactive',
    NO_ACCESS: 'No access',
    ACTIVATION_SEND: 'Activation mail is send',
    ACTIVE_ACCOUNT: 'Active your account',
    BACK_TO_START: 'Back to start screen',
    BACK_TO_LOGIN: 'Back to login',
    SELECT_COMPANY: 'Select a company',
    PASSWORD_CHANGED: 'Password is changed',
    NO_ACCOUNT_FORMKLUB: 'No account with FormKlub yet?',
    EMAIL_ADDRESS: 'E-mail address',
    EMAIL: 'Email',
    ACCOUNT_CREATED: 'Account created',
    ACCOUNT_CREATED_INFO: 'You will now receive an activation email from us at the email address you entered. The received email contains an activation link with which you can activate your account. Once you have done this, the account is ready for use.',
    ADDRESS: 'Address',
    STREET: 'Street',
    INVOICE_ADDRESS: 'Invoice address',
    CONTACT: 'Contact',
    ACCOUNT: 'Account',
    PHONE: 'Phone',
    NAME: 'Name',
    CREATE_TEMPLATES: 'Create templates',
    HOUSE_NUMBER: 'Building number',
    HOUSE_NUMBER_ADDITION: 'Addition',
    ZIPCODE: 'Zipcode',
    COUNTRY: 'Country',
    CITY: 'City',
    ACCOUNT_DATA: 'Account data',
    CONTACT_DATA: 'Contact data',
    FIRST_NAME: 'First name',
    INSERTION: 'Insertion',
    LAST_NAME: 'Last name',
    ACCOUNT_CREATE: 'Create account',
    COMPANY_NAME: 'Company name',
    NO_VIHB: 'If you do not have a VIHB number, click',
    HERE: 'here',
    ROLES: 'Roles',
    SETTINGS: 'Settings',
    DOCUMENTS: 'Documents',
    SHARE: 'Share',
    PROJECT_NR: 'Project nr.',
    DESCRIPTION: 'Description',
    CLIENT: 'Client',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    CONTRACT_NR: 'Contract nr.',
    REMARK_PLANNING: 'Remark planning',
    REMARK_DRIVER: 'Remark driver',
    REMARKS: 'Remarks',
    REGIO: 'Region',
    MANUALS: 'Manuals',
    FAQ: 'frequently asked questions',
    NEED_HELP: 'Need help?',
    TEMPLATE_COLOR: 'Template color',
    DOWNLOADS: 'Downloads',
    TEMPLATE_FROM: 'Template from',
    SEND: 'Send',
    CONTACTS: 'Contacts',

    // INDEX
    PROJECT: 'Projects',
    PROJECTS: 'Projects',
    REGION: "Regions",
    TEMPLATE: 'Templates',
    BUSINESSRELATION: 'Relations',
    ORDERTYPE: 'Order types',
    COMPANY: 'Company',
    WAYBILL: 'Waybills',
    RIDES: 'Rides',
    ORDERTYPES: 'Order types',
    COMPANIES: 'Companies',
    OVERLOADED: 'Overloading',
    LINKTMS: 'Link Tms',
    GOODSITEM: 'Ride',
    GOODSITEMS: 'Rides',

    // PLACEHOLDERS
    TYPE_TO_SEARCH: 'Type here to search...',
    TYPE_AT_LEAST_TO_SEARCH: 'Enter at least 3 characters to search',
    CURRENTLY_SEARCHING: 'Searching..',
    PLEASE_WAIT: 'A lot of information is currently being collected. We ask for your patience.',
    TYPE_REMARK: 'Type your comment here...',

    //VALIDATION
    ROLE_MANDATORY: 'Role is required',
    USER_MANDATORY: 'User is required',
    REQUIREMENTS_PASSWORD: 'Password must have at least 10 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 number and a special character.',
    FIELD_CANNOT_BE_EMPTY: 'Veld mag niet leeg zijn',
    EMAIL_IS_NOT_VALID: 'Email is not valid',
    FIELD_IS_MANDATORY: 'Field is required',

    //STATES
    STATE_ALL: 'All',
    STATE_TEMPLATE_ALL: 'All',
    STATE_TEMPLATE_RECEIVED: 'Received',
    STATE_TEMPLATE_CREATED: 'Created',
    STATE_TEMPLATE_BUSY: 'In use',
    STATE_TEMPLATE_TEMP_CLOSED: 'Temporary closed',
    STATE_TEMPLATE_FINISHED: 'Finished',
    STATE_TEMPLATE_DELETED: 'Deleted',
    STATE_TEMPLATE_REJECTED: 'Rejected',

}

export {nl, en};