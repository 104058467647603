import * as React from 'react';
import PropTypes from "prop-types";
import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {getTableFiltersInArray} from "../../../store/selectors/datatableSelector";
import i18n from "../../../i18n/i18n";
import {renderColorIndicator} from "../CellRenderers/ColorIndicator";

export default function CustomColorSelectFilter(props) {
    const {item, applyValue, index, extra} = props;
    const correctField = extra.column.filterField || extra.column.field;
    const tableTabFilter = useSelector(state => getTableFiltersInArray(state, index, correctField));
    const [open, setOpen] = React.useState(false);

    const handleFilterChange = (event, newValue) => {
        applyValue({...item, value: newValue});
    };

    // const getColorLabel = (value) => {
    //     try {
    //         const colorArray = [
    //             ['#FF0000', 'Rood'], ['#FFA500', 'Oranje'], ['#FFFF00', 'Geel'],
    //             ['#008000', 'Groen'], ['#000080', 'Blauw'], ['#00FFFF', 'Licht blauw'],
    //             ['#EE82EE', 'Roze'], ['#800080', 'Paars'], ['#FFFFFF', 'Wit'],
    //             ['#808080', 'Grijs']
    //         ];
    //
    //         return colorArray.find(color => color[0] === value)?.[1] || ' ';
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    return (
        <Autocomplete
            id="color-select"
            options={tableTabFilter}
            autoHighlight
            sx={{input: {textIndent: '-9999px'}}}
            name={correctField}
            value={props.item.value || ''}
            onChange={handleFilterChange}
            getOptionLabel={(option) => option || ''}
            isOptionEqualToValue={(option, value) => option || '' === value}
            renderOption={(props, option, i) => {
                return (
                    <React.Fragment key={props['data-option-index']}>
                        <Box id={props.id} onClick={props.onClick} role={props.role} data-option-index={props['data-option-index']} onMouseMove={props.onMouseMove} onTouchStart={props.onTouchStart} className={props.className} component="li" sx={{
                            '& > img': {
                                mr: 2,
                                flexShrink: 0
                            }, marginLeft: '-8px', padding: 0
                        }}>
                            {renderColorIndicator({value: option, tooltipPlacement: 'left'})}
                            {/*<Typography variant={'body2'} sx={{marginLeft: 3, fontSize:'0px'}}>{getColorLabel(option)}</Typography>*/}
                        </Box>
                    </React.Fragment>
                )
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            size="small"
            disableClearable
            noOptionsText={i18n.t('NO_OPTIONS')}
            renderInput={(params) => {
                return (
                    <TextField
                        sx={{maxWidth: '8px', alignSelf: 'center'}}
                        {...params}
                        label={i18n.t('TEMPLATE_COLOR')}
                        autoComplete="off"
                        slotProps={{
                            inputLabel: {shrink: true},
                            input: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start" onClick={() => setOpen(!open)}>
                                        <Box sx={{marginLeft: 1}}>
                                            {renderColorIndicator({value: item.value, tooltipPlacement: 'left'})}
                                        </Box>
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                )
            }}
        />
    )
}

CustomColorSelectFilter.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.string.isRequired,
    applyValue: PropTypes.func.isRequired,
    extra: PropTypes.object.isRequired,
    id: PropTypes.number,
    onClick: PropTypes.func,
    role: PropTypes.string,
    onMouseMove: PropTypes.func,
    onTouchStart: PropTypes.func,
    className: PropTypes.string,
    'data-option-index': PropTypes.number
};
