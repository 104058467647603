import {put, call, takeLeading} from 'redux-saga/effects';
import {
    FETCH_GPS_SUCCESS_SAGA, FETCH_GPS_FAILURE_SAGA, FETCH_GPS_REQUEST_SAGA,
} from "../actions/actionTypes";
import resource from "../../api/axios-gps";

/**
 *
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* fetchGpsSaga(action) {

    try {
        const {
            url,
        } = action;

        const fullUrl = url;
        const index = 'gps';

        // Use the provided resource or the default axios
        const rest = resource ;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});
        // Dispatch the success action with the response data
        yield put({type: FETCH_GPS_SUCCESS_SAGA, index, data: response.data});
    } catch (error) {
        // console.error(error)
        // Dispatch the failure action in case of an error
        yield put({type: FETCH_GPS_FAILURE_SAGA, index: action.index, error: error.message});
    }
}

/**
 * Watch for FETCH_GPS_REQUEST_SAGA and run fetchTabsSaga on latest occurrence
 * @returns {Generator<*, void, *>}
 */
export function* watchGpsData() {
    yield takeLeading(FETCH_GPS_REQUEST_SAGA, fetchGpsSaga);
}
