import boa from "../../api/axios-auth";
import * as actionsTypes from "./actionTypes";
import {setFormAlert} from "./dataTable";
import i18n from "../../i18n/i18n";

/**
 *
 * @param key
 * @param email
 * @returns {(function(*): void)|*}
 */
export const checkAuthKey = (key, email) => {
    return dispatch => {
        dispatch(startUpdate())
        let url = '/reset-password/exists?code=' + key + '&email=' + email;
        boa.get(url).then(() => {
            dispatch(authKeyValid())
        }).catch(() => {
            dispatch(changePasswordFail('Deze link is reeds gebruikt of is niet langer geldig'))
        })
    }
}

/**
 *
 * @param key
 * @param email
 * @param password
 * @returns {(function(*): void)|*}
 */
export const doResetPassword = (key, email, password) => {
    return dispatch => {
        dispatch(startUpdate())
        let url = '/reset-password?code=' + key + '&email=' + email;
        boa.post(url, {
            'password': password
        }).then(() => {
            dispatch(passwordChanged())
            dispatch(setFormAlert(true, 'success', i18n.t('SUCCESS_PASSWORD_CHANGED')))
        }).catch(err => {
            dispatch(changePasswordFail(err.response || err.message))
            dispatch(setFormAlert(true, 'error', err.response || err.message))
        })
    }
}

/**
 *
 * @param email
 * @returns {(function(*): void)|*}
 */
export const requestNewPassword = (email) => {
    return dispatch => {
        dispatch(startUpdate())
        let url = '/authenticate/forgot-password';
        boa.put(url, {
            "email": email,
            "client_id": process.env.REACT_APP_AUTH_CLIENT_ID,
            "client_secret": process.env.REACT_APP_AUTH_CLIENT_SECRET,
        }).then(response => {
            dispatch(emailRequested(response.data))
        })
            .catch(err => {
                dispatch(changePasswordFail(err.response.data.message))
            })
    }
}

/**
 *
 * @param data
 * @returns {{data, action: string, type: string, message: string}}
 */
const emailRequested = (data) => {
    return {
        type: actionsTypes.PASSWORD_REQUESTED,
        data: data,
        action: "reset-password",
        message: "Wachtwoord bijgewerkt"
    }
}

/**
 *
 * @returns {{type: string}}
 */
const startUpdate = () => {
    return {
        type: actionsTypes.PASSWORD_RESET_START,
    }
}

/**
 *
 * @returns {{type: string}}
 */
const passwordChanged = () => {
    return {
        type: actionsTypes.PASSWORD_RESET_SUCCESS,
    }
}

/**
 *
 * @returns {{type: string}}
 */
const authKeyValid = () => {
    return {
        type: actionsTypes.PASSWORD_AUTH_KEY_VALID,
    }
}

/**
 *
 * @param message
 * @returns {{errorState: boolean, type: string, error}}
 */
const changePasswordFail = (message) => {
    return {
        type: actionsTypes.PASSWORD_RESET_FAIL,
        error: message,
        errorState: true,
    }
}
