import React, {lazy, Suspense, useEffect} from 'react'
import {styled} from '@mui/material/styles';
import Header from "../../../components/Header/Formklub/Header";
import {Container, IconButton} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {AuthRoute} from "../routes/AuthRoute";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {setFormAlert} from "../../../store/actions/dataTable";
import LoaderBackdrop from "../../../components/DataTable/LoaderBackdrop";
import CloseIcon from '@mui/icons-material/Close';
import {
    getActiveCompanySettings, getActiveCompanyUUID,
    isDriverForCompany,
    isEmployeeForCompany,
    isManagerForCompany, isUpperManagerForCompany, peerAuthInitialized
} from "../../../store/selectors/companySelector";
import {getUser, isScoretraceAdmin, isTokenSet} from "../../../store/selectors/userSelector";
import {
    getScopeFormklubInitialized, getScopeFormklubIsActive,
    getScopePeerCompaniesAmount, getScopePeerDataUser, getScopePeerInitialized, getScopePeerState,
    getScopesArray
} from "../../../store/selectors/scopeSelector";
import {getNotifications} from "../../../store/selectors/datatableSelector";
import {enqueueSnackbar, closeSnackbar} from 'notistack'
import {authCheckState, getAuthItemsMe} from "../../../store/actions/auth";
import {getHasRightsByIndex, userHasRights} from "../../../store/selectors/actionsRightsSelector";
import Check from "../../../components/Forms/Templates/Check/Check";
import ActivateAccount from "../../../containers/Auth/Activate/ActivateAccount";
import InstructionPage from "../../../components/Forms/Instructions/InstructionPage";

const PREFIX = 'Layout';

const classes = {
    root: `${PREFIX}-root`,
    content: `${PREFIX}-content`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
    },

    [`& .${classes.content}`]: {
        width: '0%',
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: 64,
    }
}));

const GebruikersRoute = lazy(() => import("../routes/peer/beheer/GebruikersRoute"));
const OrganisationRoute = lazy(() => import("../routes/peer/beheer/OrganisationRoute"));
const ProjectenRoute = lazy(() => import("../routes/peer/ProjectenRoute"));
const RelationRoute = lazy(() => import("../routes/peer/RelationRoute"));
const TemplatesRoute = lazy(() => import("../routes/peer/TemplatesRoute"));
const BegeleidingsbrievenRoute = lazy(() => import("../routes/formklub/BegeleidingsbrievenRoute"));
const RittenRoute = lazy(() => import("../routes/formklub/RittenRoute"));
const RapportageRoute = lazy(() => import("../routes/formklub/RapportageRoute"));
const Map = lazy(() => import("../../../components/Map/Map"));
const ChangePassword = lazy(() => import("../../../components/Forms/Users/ChangePassword"));
const RegiosRoute = lazy(() => import("../routes/peer/beheer/RegiosRoute"));
const OrderTypeRoute = lazy(() => import("../routes/peer/beheer/OrderTypeRoute"));
const PageNotFound404 = lazy(() => import("../../../components/Errors/PageNotFound404"));
const NotVerified401 = lazy(() => import("../../../components/Errors/NotVerified401"));
const NotAuthorized403 = lazy(() => import("../../../components/Errors/NotAuthorized403"));
const CompanyInactive = lazy(() => import("../../../components/Errors/CompanyInactive"));
const SelectCompanyForm = lazy(() => import("./SelectCompanyForm"));
const CompanyDetails = lazy(() => import("../../../components/Forms/CompanySettings/CompanyDetails"));

/**
 *
 * @returns {Element}
 * @constructor
 */
export default function Layout() {

    const alert = useSelector(state => getNotifications(state));
    const isManager = useSelector(state => isManagerForCompany(state));
    const isUpperManager = useSelector(state => isUpperManagerForCompany(state));
    const isAdmin = useSelector(state => isScoretraceAdmin(state));
    const isDriver = useSelector(state => isDriverForCompany(state));
    const isEmployee = useSelector(state => isEmployeeForCompany(state));
    const activeCompany = useSelector(state => getActiveCompanyUUID(state));
    const activeCompanySettings = useSelector(state => getActiveCompanySettings(state));
    const scopeArray = useSelector(state => getScopesArray(state));
    const scopePeerIsActive = useSelector(state => getScopePeerState(state));
    const scopePeerIsInitialized = useSelector(state => getScopePeerInitialized(state));
    const scopePeerDataUser = useSelector(state => getScopePeerDataUser(state));
    const scopeFormklubIsActive = useSelector(state => getScopeFormklubIsActive(state));
    const scopeFormklubIsInitialized = useSelector(state => getScopeFormklubInitialized(state));
    const checkTokenSet = useSelector(state => isTokenSet(state));
    const totalCompanies = useSelector(state => getScopePeerCompaniesAmount(state));
    const rightsPerIndex = useSelector(state => getHasRightsByIndex(state));
    const rightsInitialized = useSelector(state => peerAuthInitialized(state));
    const authUser = useSelector(state => getUser(state));
    const [notVerified, setNotVerified] = React.useState(false);
    const [notAllowed, setNotAllowed] = React.useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const hideMenu = location.pathname?.toLowerCase().includes('activate') || location?.pathname.toLowerCase().includes('check');
    const hasRights = useSelector(state => userHasRights(state));

    // =======================================
    // Notifications alerts in portal
    // =======================================
    useEffect(() => {
        if (alert?.state) {
            enqueueSnackbar(alert?.message || '', {
                persist: alert?.persist || false,
                variant: alert?.variant || 'default',
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                duration: alert?.duration || 5000,
                action: (snackbarId) => (
                    <IconButton
                        onClick={() => {
                            closeSnackbar(snackbarId);
                            dispatch(setFormAlert(false, '', '', false));
                        }}
                        size="large"
                    >
                        <CloseIcon sx={{color: '#FFFFFF'}}/>
                    </IconButton>
                ),
            });
        }
    }, [alert?.count]);

    // ===============================
    // DISPATCH - Fetch Alle auth items van user
    // ===============================
    useEffect(() => {
        if (!activeCompany || !scopePeerIsActive) {
            return;
        }
        dispatch(getAuthItemsMe());
    }, [activeCompany]);


    // =================================
    // DISPATCH - On refresh Verify loggedIn user
    // =================================
    useEffect(() => {
        if (!checkTokenSet) {
            dispatch(authCheckState())
        }
    }, []);


    // =================================
    // CHECK - Are there companies
    // =================================
    useEffect(() => {
        if (scopePeerIsActive) {
            if (totalCompanies === 0) {
                console.error('Logged in but user has no companies', 'user:', scopePeerDataUser.uuid, '-', 'authUser sub:', authUser?.sub)
                setNotAllowed(true)
            }
        }
    }, [scopePeerIsActive]);

    // =================================
    // CHECKS - Is user active?
    // =================================
    useEffect(() => {

        // Object is empty
        if (!Object.values(scopePeerDataUser).length) {
            return;
        }
        // Account Activated check
        if (scopePeerDataUser.state_id === '1') {
            setNotVerified(true)
        }
        // Account Disabled /  no active auth assignments
        if (scopePeerDataUser.state_id === '90' || !scopePeerDataUser.isActive || activeCompany && !hasRights && rightsInitialized) {
            setNotAllowed(true)
        }
    }, [scopePeerDataUser, rightsInitialized]);

    // =================================
    // CHECKS - Is it a driver?
    // =================================
    useEffect(() => {
        if (isDriver) {
            setNotAllowed(true)
        }
    }, [isDriver]);


    //========================================
    //  Check Scopes for allowed routes
    //========================================
    let layout = [];
    let redirects = [];

    /**
     *
     * @param index
     * @returns {boolean}
     */
    const checkRights = (index) => {
        const checkIndex = index?.toLowerCase();
        return !!(rightsPerIndex[checkIndex] && rightsPerIndex[checkIndex].includes('read')) || (typeof rightsPerIndex === "boolean" && rightsPerIndex);
    }

    //@todo optimize this file
    // For each scope set routes
    scopeArray.forEach((item) => {
        if (notAllowed) {
            return;
        }

        // Set Routes
        switch (item.scope) {
            case "PEER":
                //Routes
                layout.push({
                    scope: 'PEER',
                    path: '/hulp-nodig',
                    index: '',
                    isAuthorized: true,
                    component: <InstructionPage/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Projecten',
                    index: 'project',
                    isAuthorized: scopePeerIsActive || false,
                    component: <ProjectenRoute/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Relaties',
                    index: 'business_relation',
                    isAuthorized: isAdmin || isManager || isEmployee,
                    component: <RelationRoute/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Templates',
                    index: 'template',
                    isAuthorized: scopePeerIsActive || false,
                    component: <TemplatesRoute/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/change-password',
                    isAuthorized: true,
                    component: <ChangePassword/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Bedrijf/Instellingen',
                    isAuthorized: scopePeerIsActive && (isAdmin || isUpperManager) || false,
                    component: <CompanyDetails/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Beheer/Gebruikers',
                    index: 'user',
                    isAuthorized: isAdmin || isManager,
                    component: <GebruikersRoute/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/geen-toegang',
                    index: '',
                    isAuthorized: true,
                    component: <NotAuthorized403/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Beheer/Regios',
                    index: 'region',
                    isAuthorized: scopePeerIsActive || false,
                    component: <RegiosRoute/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Beheer/Werkzaamhedentypes',
                    index: 'order_type',
                    isAuthorized: isAdmin || isManager,
                    component: <OrderTypeRoute/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Beheer/change-password',
                    isAuthorized: scopePeerIsActive || false,
                    component: <ChangePassword/>
                })
                layout.push({
                    scope: 'PEER',
                    path: '/Admin/Organisaties',
                    index: 'company',
                    isAuthorized: isAdmin,
                    component: <OrganisationRoute/>
                })
                //Template check
                layout.push({
                    scope: 'PEER',
                    path: "/check/:auth_key",
                    index: '',
                    isAuthorized: true,
                    component: <Check/>
                })
                //Active account
                layout.push({
                    scope: 'PEER',
                    path: "/activate/:activation_key",
                    index: '',
                    isAuthorized: true,
                    component: <ActivateAccount/>
                })

                //GPS
                layout.push({
                    scope: 'PEER',
                    path: '/Kaart',
                    isAuthorized: isAdmin || isManager || isEmployee,
                    component: <Map/>
                })

                //Redirects
                if (item?.isActive) {
                    redirects.push({from: '/', to: '/Projecten'})
                }
                redirects.push({from: '/Beheer', to: '/Beheer/Regios'})
                if (notAllowed) {
                    redirects.push({from: '*', to: '/geen-toegang'})
                }
                break;
            case "GPS":
                break;
            case "FORMKLUB":

                //Routes
                layout.push({
                    scope: 'PEER',
                    path: '/change-password',
                    isAuthorized: true,
                    component: <ChangePassword/>
                })
                layout.push({
                    scope: 'FORMKLUB',
                    path: '/Begeleidingsbrieven',
                    index: 'waybill',
                    isAuthorized: scopeFormklubIsActive,
                    component: <BegeleidingsbrievenRoute/>
                })
                layout.push({
                    scope: 'FORMKLUB',
                    path: '/Ritten',
                    index: 'rides',
                    isAuthorized: isAdmin || (activeCompanySettings.can_see_overload_module && (isManager || isEmployee)),
                    component: <RittenRoute/>
                })
                layout.push({
                    scope: 'FORMKLUB',
                    path: '/Rapportage',
                    index: 'report',
                    isAuthorized: isAdmin || (activeCompanySettings?.can_see_overload_module && (isManager || isEmployee)),
                    component: <RapportageRoute/>
                })

                //Redirects
                if (!scopePeerIsActive && scopeFormklubIsActive) {
                    redirects.push({from: '/', to: '/Begeleidingsbrieven'})
                }
                break;
            default:
                layout.push({path: '*', isAuthorized: true, component: <NotAuthorized403/>})
                break;
        }
    })


    if (notVerified) {
        layout = layout.map((item) => {
            if (Object.prototype.hasOwnProperty.call(item, "component") && (item.scope === 'PEER' || item.scope === 'FORMKLUB') && item.path !== "/change-password" && item.path !== "/activate/:activation_key") {
                item.component = <NotVerified401/>
            }
            return item;
        })
    }

    /**
     *
     * @returns {Element}
     */
    const checkAllowed = () => {
        //1.  Check allowed
        if (notAllowed) {
            return <NotAuthorized403 isDriver={isDriver}/>;
        }


        if (!hideMenu) {
            //2. No company selected, clear routes
            if (!activeCompany && scopePeerIsActive) {
                if (totalCompanies !== 0) {
                    return <SelectCompanyForm isAdmin={isAdmin}/>;
                } else {
                    return <CompanyInactive/>;
                }
            }
        }
    }

    /**
     *
     * @returns {React.JSX.Element|null}
     */
    const getRoutes = () => {

        if (notAllowed) {
            return null;
        }

        // Cannot set routes -> rights first need to be initialized
        if (scopePeerIsActive) {
            if (!rightsInitialized && !location.pathname.includes('/check/') && !location.pathname.includes('/activate/')) {  //@todo temp fix does not need to wait for rights when auditing template or activating account
                if(notAllowed){
                    return <NotAuthorized403/>
                }
                return <></>
            }
        }

        // Map function redirects routes
        const renderRedirects = () => (
            redirects.map((item, key) => (
                <Route key={key} path={item.from} element={<Navigate to={item.to}/>}/>
            ))
        );

        // Map function normal routes
        const renderRoutes = () => (
            layout.map((item, key) => {

                let authorized = item.isAuthorized;
                let path = item.path;

                if (scopePeerIsActive) {
                    if ((authorized) && (item.index && !checkRights(item.index, 'read'))) {
                        authorized = false;
                    }
                }
                if(authorized === 0){
                    authorized = false;
                }

                return (
                    <Route
                        key={key}
                        path={path}
                        element={<AuthRoute key={path} isAuthorized={authorized} component={item.component}/>}
                    />
                );
            })
        );

        return (
            <Routes>

                {renderRedirects()}
                {renderRoutes()}

                {/* Error 404 */}
                <Route path="*" element={<PageNotFound404/>}/>

            </Routes>
        );
    };

    if ((!scopePeerIsInitialized) || (!scopeFormklubIsInitialized)) {
        return <LoaderBackdrop open={true}/>;
    }

    return (
        <Root className={classes.root}>
            <Header headerOnly={hideMenu}/>
            <Container component={"main"} maxWidth={false} className={classes.content}>
                <Suspense fallback={<LoaderBackdrop open={true}/>}>
                    {checkAllowed()}
                    {getRoutes()}
                </Suspense>
            </Container>
        </Root>
    );
}
