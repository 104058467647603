import Grid from "@mui/material/Grid2";
import React, {useEffect, useState} from "react";
import customClasses from "./TemplateForm.module.css";
import RadioButton from "./Components/RadioButton";
import PropTypes from "prop-types";
import SearchDialog from "../Templates/DialogDetails/SearchVihbDialog";
import SearchIcon from "../../Controls/SearchIcon";
import SimpleDialog from "../../Dialog/SimpleDialog";
import {FastField} from "formik";
import {FormTextField} from "./Components/FormTextField";

export default function TemplateFormSender(props) {

    const {
        formField: {
            disposer_type,
            senderparty_name,
            senderpartyaddress_street_name,
            senderpartyaddress_house_no,
            senderpartyaddress_house_no_addition,
            senderpartyaddress_postal_code,
            senderpartyaddress_city_name,
            senderparty_identifier_value,
        }
    } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const group = "senderparty";

    // =====================
    // Handle Modal
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };
    // =====================
    // Handle selected company
    // =====================
    const handleCompanySelected = (selectedCompany) => {
        props.setFieldValue(group + '.name', selectedCompany.name || '');
        props.setFieldValue(group + '.address_street_name', selectedCompany.street_name || '');
        props.setFieldValue(group + '.address_house_no', selectedCompany.building_number || '');
        props.setFieldValue(group + '.address_house_no_addition', selectedCompany.building_number_addition || '');
        props.setFieldValue(group + '.address_postal_code', selectedCompany.postal_code || selectedCompany.postal_zone || '');
        props.setFieldValue(group + '.address_city_name', selectedCompany.city_name || '');
        props.setFieldValue(group + '.identifier_value', selectedCompany.vihb_full || selectedCompany.identifier_value || '');
        closeModal();
    };

    // =====================
    // Dialog
    // =====================
    const dialog = <SimpleDialog
        title="Zoek Afzender"
        fields={props}
        size="lg"
        fullWidth={true}
        open={openDialog}
        handleClose={closeModal}>
        <SearchDialog
            data={props.organisationData || []}
            handleCompanySelected={handleCompanySelected}
        />
    </SimpleDialog>

    const radioOptions = [
        {labelNumber: '1', label: `(primaire) ontdoener`, value: 1},
        {labelNumber: '2', label: "ontvanger", value: 2},
        {labelNumber: '3', label: "handelaar", value: 3},
        {labelNumber: '4', label: "bemiddelaar", value: 4}
    ];

    // ========================================
    // First load Template handle radiobuttons
    // ========================================
    useEffect(() => {
        if (props.templateFormInitialised || props.action !== 'add') {
            return;
        }
        if (!props.project || props.project.length === 0) {
            return;
        }
        setFirstLoad(false)
    }, [props.project])

    useEffect(() => {
        if (firstLoad) {
            return;
        }
        if (props.project.template_default_disposer_type && !props.templateFormInitialised) {
            props.setFieldValue('disposer_type', props.project.template_default_disposer_type || '');
        } else {
            props.setFieldValue('disposer_type', 4);
            return;
        }
        handleRadioAutoFill()
    }, [firstLoad])

    // ===============================
    // Handle radioButtons autofill
    // ===============================
    useEffect(() => {
        if (!props.values || !props.project || Object.keys(props.project).length === 0 || props.action !== 'add') {
            return;
        }
        handleRadioAutoFill()
    }, [props.values.disposer_type])

    const handleRadioAutoFill = () => {
        //Set address
        let name;
        switch (props.values.disposer_type) {
            case 1:
                name = 'disposer'
                break;
            case 2:
                name = 'receiver'
                break;
            case 3:
                name = 'trader'
                break;
            case 4:
                name = 'mediator'
                break;
            default:
                name = null
                break;
        }

        if (!name) {
            return;
        }

        handleCompanySelected(props.project[`default_${name}_address`])
    }

    // =====================
    // Sender Block
    // =====================
    return <React.Fragment>
        {dialog}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container size={12} sx={{marginTop:'-12px'}}/>
        <div className={customClasses.blockIdentifier}><strong>1</strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container className={customClasses.radioContainer} size={12}>
            <RadioButton disabled={props.disabled} options={radioOptions} label="" labelNumbers={true}
                         name={disposer_type.name} setFieldValue={props.setFieldValue}/>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {senderparty_name.label}
            </Grid>
            <Grid size={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    handleClick={openModal}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + senderparty_name.name}
                    sizeClass={customClasses.inputFull}
                    disabled={props.disabled}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {senderpartyaddress_street_name.label}
            </Grid>
            <Grid size={1}> </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    sizeClass={customClasses.inputMedium}
                    name={group + "." + senderpartyaddress_street_name.name}
                    disabled={props.disabled}
                    component={FormTextField}
                />
                <FastField
                    sizeClass={customClasses.inputSmall}
                    name={group + "." + senderpartyaddress_house_no.name}
                    disabled={props.disabled}
                    type='number'
                    autoComplete="new-password"
                    component={FormTextField}
                />
                <FastField
                    sizeClass={customClasses.inputSmall}
                    name={group + "." + senderpartyaddress_house_no_addition.name}
                    disabled={props.disabled}
                    autoComplete="new-password"
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {senderpartyaddress_postal_code.label}
            </Grid>
            <Grid size={1}> </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    sizeClass={customClasses.inputSmall}
                    name={group + "." + senderpartyaddress_postal_code.name}
                    disabled={props.disabled}
                    autoComplete="new-password"
                    component={FormTextField}
                />
                <FastField
                    sizeClass={customClasses.inputLarge}
                    name={group + "." + senderpartyaddress_city_name.name}
                    disabled={props.disabled}
                    autoComplete="new-password"
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container className={customClasses.containerMaybeRequired} size={12}>
            <Grid size={{xs: 11, md: 3}}>
                <span style={{paddingLeft: '15px'}}>{senderparty_identifier_value.label}</span>
            </Grid>
            <Grid size={1}> </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="off"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + senderparty_identifier_value.name}
                    disabled={props.disabled}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
    </React.Fragment>
};

TemplateFormSender.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    autoFillReplaceData: PropTypes.bool,
    values: PropTypes.object,
    project: PropTypes.object,
    action: PropTypes.string,
    templateFormInitialised: PropTypes.bool,
    organisationData: PropTypes.array
};